import Vue from "vue";
import Vuex from "vuex";
import contents from "./contents.js";
import { setI18nLanguage } from "@/i18n";

Vue.use(Vuex);
let domain = "https://dmb.grupoian.com/";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("dev.doubledot.es")
) {
  domain = "https://dmb-ian.dev.doubledot.es";
}
export default new Vuex.Store({
  state: {
    language: null,
    server: domain
  },
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
    },
  },
  actions: {
    changeLanguage: ({ commit }, changeTo) => {
      return new Promise((resolve) => {
        commit("setLanguage", changeTo);
        setI18nLanguage(changeTo);
        document.documentElement.setAttribute("lang", changeTo);
        resolve(changeTo);
      });
    },
  },
  getters: {
    getLanguage: (state) => state.language,
  },
  modules: {
    contents,
  },
});
